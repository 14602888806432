@font-face {
  font-family: "Intro Black";
  src: url("Intro-Black.otf") format("opentype"); /* Modern Browsers */
}

@font-face {
  font-family: "Intro Black Caps";
  src: url("Intro-Black-Caps.otf") format("opentype"); /* Modern Browsers */
}

@font-face {
  font-family: "Intro Black Inline";
  src: url("Intro-Black-Inline.otf") format("opentype"); /* Modern Browsers */
}

@font-face {
  font-family: "Intro Black Inline Caps";
  src: url("Intro-Black-Inline-Caps.otf") format("opentype"); /* Modern Browsers */
}

/* Light */

@font-face {
  font-family: "Intro Light";
  src: url("Intro-Light.otf") format("opentype"); /* Modern Browsers */
}

@font-face {
  font-family: "Intro Light Caps";
  src: url("Intro-Light-Caps.otf") format("opentype"); /* Modern Browsers */
}

/* Book */
@font-face {
  font-family: "Intro Book";
  src: url("Intro-Book.otf") format("opentype"); /* Modern Browsers */
}
@font-face {
  font-family: "Intro Book Alt";
  src: url("Intro-Book-Alt.otf") format("opentype"); /* Modern Browsers */
}
@font-face {
  font-family: "Intro Book Italic";
  src: url("Intro-Book-Italic.otf") format("opentype"); /* Modern Browsers */
  font-style: italic;
}

/* Regular */
@font-face {
  font-family: "Intro Regular";
  src: url("Intro-Regular_0.otf") format("opentype"); /* Modern Browsers */
}
@font-face {
  font-family: "Intro Regular Alt";
  src: url("Intro-Regular-Alt_0.otf") format("opentype"); /* Modern Browsers */
}
@font-face {
  font-family: "Intro Regular Caps";
  src: url("Intro-Regular-Caps_0.otf") format("opentype"); /* Modern Browsers */
}
@font-face {
  font-family: "Intro Regular Italic";
  src: url("Intro-Regular-Italic.otf") format("opentype"); /* Modern Browsers */
  font-style: italic;
}

/* Thin */
@font-face {
  font-family: "Intro Thin";
  src: url("Intro-Thin.otf") format("opentype"); /* Modern Browsers */
}
