.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.wh-full {
  width: 100%;
  height: 100%;
}

.fileButton {
  display: "inline-block"
}

.hover-pointer:hover {
  cursor: pointer;
}